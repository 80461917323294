

$dark: #373a3c;

$table-dark-bg: #373a3c;

// $topnav-background-color: #373a3c;
$max-width: 1200px;
$primary: #f47e43;
$success: #28a745;

$light: #f3f3f3;



$theme-colors: (
  primary: #f47e43,
  secondary: #4f70ce,
  light: #f3f3f3,
  dark: #434749,
  info: #ccc,
  success: #28a745,
  warning: #ffc107,
  danger: #dc3545,
  muted: #aaa
);

$black : black;

$box-shadow: 0 .5rem 1rem rgba($black, .5);
$box-shadow-sm: 0 .125rem .25rem rgba($black, .075);
$box-shadow-lg: 0 1rem 3rem rgba($black, .675);
$box-shadow-inset: inset 0 1px 2px rgba($black, .075);

$modal-backdrop-bg: rgba(0,0,0,0.5) !default;

$accordion-bg: transparent;
$accordion-button-active-bg: $light;

:root {
  --bs-table-bg: #333;

}

$font-size-base: 0.9rem !default; // Assumes the browser default, typically `16px`


// @import '~angular-tree-component/dist/angular-tree-component.css';
// @import "./bootstrap/bootstrap";
// @import "./spinner";
// @import "./utils";
// @import "./rtl";
// @import "./responsive";

@media print {
  .breadcrumb {
    display: none !important;
  }
}

$input-disabled-bg: transparent;
$input-disabled-color: #aaa;


$font-family-sans-serif: // Cross-platform generic font family (default user interface font)
  system-ui,
  // Safari for macOS and iOS (San Francisco)
  -apple-system,
  // Windows
  "Segoe UI",
  // Android
  Roboto,
  // Basic web fallback
  "Helvetica Neue", Arial,
  // Linux
  "Noto Sans",
  "Liberation Sans",
  // Sans serif fallback
  sans-serif,
  // Emoji fonts
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// $badge-color: $dark;


@import "../../node_modules/animate.css/animate.min.css";
@import "../../node_modules/bootstrap/scss/bootstrap.scss";

body,
html {
  // height: 100%;
  overscroll-behavior-y: none;
}


a {
  cursor: pointer;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

h2 {
  font-size: 1.3rem;
}

h3 {
  font-size: 1.10rem;
}

.uppercase {
  text-transform: uppercase;
}

button {
  // font-weight: 200 !important;
  background: transparent;
}

input.ng-invalid {
  // color: red !important;
}

input[readonly] {
  background: white !important;
}


.btn-link {
  text-decoration: none;
  color: #555;
}


.focus:focus:hover {
  background-color: #f79565 !important;
}

.searchbar {
  margin-bottom: auto;
  margin-top: auto;
  background-color: $dark;
  border-radius: 30px;
}

.search_input {
  border: 0;
  outline: 0;
  background: none;
  width: 80%;
  padding: 0px;
  line-height: 40px;
  transition: width .3s linear;
}

input-group {
  button {
    line-height: 40px;
  }
}

.search_input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: transparent;
}


.tab-pane .list-group a:first-child {
  border-top: 0px;
}


.search_icon {
  height: 40px;
  width: 40px;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
}

.mobile-page {

  // padding: 20px;

  .full-width {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  position: fixed;
  top: 0;
  left: 0;
  right: 0px;
  width: 100%;
  max-width: $max-width;

  >* {
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
  }

  height: 100%;
  overflow: scroll;
  width: 100%;
  // max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  background: #efefef;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;


  // .navbar {
  //   max-width: $max-width;
  //   margin-left: auto;
  //   margin-right: auto;
  // }
}


.navbar2 {
  width: 100%;
  background-color: $dark;
  align-items: start !important;

  h1 {
    font-size: 1.3rem;
    margin-bottom: 0px;
    margin-top: 5px;

  }

  &.fixed-top {
    border-bottom: 6px solid $primary !important;
    min-height: 50px !important;
  }

  // .navbar-brand {
  //   color: #fff;
  //   position: absolute;
  //   width: 100%;
  //   text-align: center;
  //   pointer-events: none;

  // }

  .nav-item>a {
    color: #999;

    &:hover {
      color: #fff;
    }

    button.btn-dark {
      // font-weight: 100;
      color: white;
      background: #444;
      border: none;
    }
  }

  .disabled {
    pointer-events: none;
  }

  button.btn-dark {
    font-weight: 100;
    color: white;
    background: #444;
    border: none;

    &:hover {
      background: #444 !important;
    }

    &:focus {
      box-shadow: none !important;
    }

    &.active {
      box-shadow: 0px !important;
      background: #f47e43 !important;
    }

    box-shadow: 0px !important
  }

  input,
  input:focus {
    // border: 0px;
    // background: #444;
    // margin-bottom: 6px !important;
    // color: #ddd !important;
    // box-shadow: 0px !important;
  }
}

.dropdown-item.disabled {
  color: #999 !important;
}

.desktop-page {
  display: flex;
  height: 100vh;
  flex-direction: column;
  flex-grow: 1;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: white;

  // tbody {
  //   display: block !important;
  //   overflow-y: scroll;
  //   height: calc(100% - 140px);
  // }

  .tableFixHead {
    // z-index:1000;
    overflow-y: auto;
    height: 90px;
  }

  // .tableFixHead > thead th,
  // .tableFixHead > thead tr {
  //   position: sticky;
  //   top: -2px;
  // }

  .table-flush {
    margin-bottom: 0px;
    border: 0px;
  }

  /* Just common table stuff. Really. */
  table {
    width: 100%;
  }

  th,
  td {
    padding: 8px 16px;
  }

  th {
    background: #eee;
  }

  // table {
  //   height: 100%;
  //   overflow: hidden;
  // }

  table td,
  table th {
    white-space: nowrap;
    padding: 0px;
    padding-right: 5px;
    max-height: 100%;


    .badge {
      margin-left: 5px;
      padding: 5px;
    }
  }

  .dropdown-menu {
    box-shadow: 0px 0px 15px 0px #555;
  }

  .editable {
    margin: 0px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAIUlEQVQYV2NkQAKXL19uYITxQRxdXV2IAIwDYjMic0ACAN4vDv6/jr/tAAAAAElFTkSuQmCC
) repeat;



  }


  app-input-double-um {
    width: auto;

    input {
      text-align: end;
      min-width: 40px;
      width: auto;
    }

    height: auto;
    display: flex;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAIUlEQVQYV2NkQAKXL19uYITxQRxdXV2IAIwDYjMic0ACAN4vDv6/jr/tAAAAAElFTkSuQmCC
) repeat;

    .editable {
      background: none;
    }
  }

  tr[draggable] {
    top: 00px;
  }

  tr.dragtarget {
    td {
      // padding-top:40px;
      border: 0px solid red;
      border-top: 2px !important;
    }

    background-color: #888 !important;
  }



  input-number input {
    text-align: right;
    
  }

  td input:not([type=checkbox]),
  input-number input {
    outline: none !important;
    //  background: transparent !important;
  }
  

  .side-card {
    background: theme-color("light");
    // max-height: 115vh;
    overflow-y: scroll;
    border-left: 2px solid theme-color("muted");
    position: fixed;
    top: 0px;
    right: 0px;
    height: 100%;
    padding-top: 64px;


  }

  .canvas {
    overflow-x: scroll;
    overflow-y: scroll;

    .table-responsive {
      overflow-x: hidden;
    }
  }

  table .active {
    background: theme-color("light")
  }
}



input-number {
  display: block;
  padding-left: 0px !important;
  padding-right: 0px !important;
  &.text-primary {
    input {
      color: $primary;
    }
  }
}


input-number input {
  padding-left: 0px !important;
  padding-right: 0px !important;
  min-width: 30px;
}

input-number input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  
}

/* MOBILE-FIRST PAGES */

app-forklift-page {
  @extend .desktop-page;
  padding: 0px;
  display: flex;
  // position:absolute;
  // max-height: 100%;

  video {
    height: calc(100vh - 55px);
    width: 100%;
  }

  canvas {
    height: calc(100vh - 55px);
    width: 100%;
    position: absolute;
    z-index: 10000;
    pointer-events: none;
  }

  .scrollable {
    max-height: calc(100vh - 60px);
  }

  app-barcode-reader-dynamsoft {
    position: static;
  }
}


app-home {
  @extend .mobile-page;
  padding: 0px;
}

app-contact {
  @extend .mobile-page;
  padding: 0px;
  overflow: none;


  &.embedded {
    background: white !important;

    display: block;
    position: relative;
    max-width: 100%;
    // max-height: 80%;
    text-align: left;

    .navbar {
      background: transparent !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    /* Sticky tabs */
    .nav-tabs-wrapper .nav-item {
      margin-right: 0.5rem;
    }
    
    .nav-tabs-wrapper .nav-link {
      border-radius: 0.25rem;
      padding: 0.5rem 1rem;
    }
    
    .nav-tabs-wrapper .btn-sm {
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
      height: fit-content;
    }

/* Main content area */
.main-content {
  background-color: #fff;
  height: calc(100vh - 56px);
}

/* Message sidebar */
.message-sidebar {
  background-color: #f8f9fa;
  border-right: 1px solid #dee2e600;
  transition: width 0.3s ease;
  //eìheight should be 100% of the screen
  height: auto
}
    
    .list-group {
      margin-top: 0px !important;
  

    }

    .dropdown-divider {
      display: none;
    }

    app-navbar {
      background: transparent !important;
    }

  }

}


app-edit-contact-detail {
  @extend .mobile-page;
}

app-cantieri-detail {
  @extend .mobile-page;
}

app-picking-internal {
  @extend .mobile-page;
  background: #fafafa;
  border-left: 1px;
  border-right:1px;
  border-color: #ccc;
}

app-sale-picking-modal,
app-cantieri-gru,
app-cantieri-foto,
app-cantieri-checklist,
app-cantieri-attivita,
app-cantieri-presenze,
app-cantieri-search,
app-load,
app-load-detail,
app-load-picking,
app-picking-detail,
app-purchase,
app-offers-search,
app-sales,
app-package-detail,
app-shipping-search,
app-shipping-detail,
app-shipping-package,
app-modify-attributes,
app-receipt-search,
app-receipt-detail,
app-search-detail,
app-search,
app-search-results {
  @extend .mobile-page;
  padding: 0px;
}

app-root {
  display: block;
  height: 100%;
}

// app-load {
//   @extend .mobile-page;
//   padding: 0px;
// }

// app-load-detail {
//   @extend .mobile-page;
//   padding: 0px;
// }

// app-load-picking {
//   @extend .mobile-page;
//   padding: 0px;

//   input {
//     border: 0px;
//   }
// }


app-load-row-detail {
  // @extend .mobile-page;
  padding: 0px;
  background: black;

  .overlay {
    position: absolute;
    bottom: 30%;
    left: 2%;
    width: 96%;
    background: white;
    border-radius: 5px;
  }


}

/* DESKTOP-FIRST PAGES */
app-inventory,
app-sale-order-editor,
app-sales-detail,
app-sale-order-detail,
app-purchase-detail,
app-purchase-order-detail,
app-ddt-viewer,
app-deal-detail,
app-catalogue,
app-badge-read {
  // @extend .zoomed;
  @extend .desktop-page;
  min-height: 100vh;
}

app-deal-detail {


  
  .card h3 {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  


  .col-4 {

    max-width: none;
  }
}

app-sale-order-detail {
  overflow: hidden !important;
}

app-order-detail {
  height: 100%;

  // background: red;
  table {
    // margin-bottom:40px
  }
}

/* COMPONENTS */



.zoomed {
  zoom: 85%;

  input[type=checkbox] {
    /* Double-sized Checkboxes */
    // transform: scale(1.2);
  }
}

.editable input[type=text],.editable input-number  {
  outline: 0 !important;
  background: 0 0;
}

.editable select {
  background: transparent;
  border: none;
}

.list-label {
  font-size: 80%;
  font-weight: bold;
  margin-left: 15px;
  color: theme-color("dark")
}

/* Modify the desktop-page class to prevent double scrollbars */
.desktop-page {
  overflow: hidden; /* Changed from scroll to hidden */
}

/* Update trello-like styles */
.trello-like {
  display: flex;
  width: 100%;
  height: calc(100vh - 250px); /* Adjust based on your header height */
  overflow-x: auto;
  overflow-y: hidden;
  padding: 1rem;

  /* Your existing nested styles */
  ul {
    @include list-unstyled();
    width: 350px;
    min-width: 270px;
    border-radius: 4px;
    background: #ddd;
    margin-right: 20px;
    height: 100%; /* Added for vertical scrolling */
    display: flex;
    flex-direction: column; /* Added for proper card layout */

    &.large {
      width: 550px;
      min-width: 370px;
    }

    /* Make the cards container scrollable */
    > div {
      overflow-y: auto;
      flex: 1;
      padding-bottom: 10px;
    }
  }

  ul li {
    cursor: pointer;
    padding: 10px;
    margin: 7px;
    box-shadow: 0px 0px 1px #000;
    border-radius: 4px;
    background: white;
  }

  ul > li:first-child {
    background: #ddd;
    box-shadow: none;
    flex-shrink: 0; /* Prevent header from scrolling */
  }

  .empty-drop-list {
    background: #ddd !important;
    box-shadow: none !important;
    border: 2px dashed rgba(0,0,0,0.1);
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .cdk-drop-list-dragging .empty-drop-list {
    border-color: rgba(0,0,0,0.2);
    background: #d5d5d5 !important;
  }

  .cdk-drag-preview {
    box-shadow: 0 5px 15px rgba(0,0,0,.3);
  }

  .cdk-drag-placeholder {
    opacity: 0.3;
  }

  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  /* Custom scrollbar styling */
  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
  }

  /* Scrollbar for individual columns */
  ul > div::-webkit-scrollbar {
    width: 6px;
  }

  ul > div::-webkit-scrollbar-track {
    background: transparent;
  }

  ul > div::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0.1);
    border-radius: 3px;
  }

  ul > div::-webkit-scrollbar-thumb:hover {
    background: rgba(0,0,0,0.2);
  }


}

app-deals-dashboard,
app-sales-board,
app-purchase-board,
app-production-detail,
app-production-search {
  @extend .desktop-page;


  // .modal {
  //   background: rgba(0, 0, 0, 0.8);
  //   min-width: 500px;

  //   .modal-header {
  //     border: none;
  //   }
  // }


}


app-production-search {
  // -webkit-overflow-scrolling: touch;
  // overflow-scrolling: touch;
  overflow: hidden !important;
}

app-production-detail {
  .table td {
    vertical-align: middle !important;
  }
}


app-deals-dashboard {
  .nav {
    width: 100%;
  }

  .trello-like {
    // margin-top: 110px;
  }

  button {}

  .btn:disabled i {
    background-color: #333 !important;
  }
}


app-barcode-reader-dynamsoft,
app-sale-picking-modal {
  user-select: none;
  -webkit-user-select: text;

  * {
    user-select: none;
    -webkit-user-select: text;
  }

  .counter {
    top: 20px;
    left: 20px;
    color: white;
    position: absolute;
    z-index: 100000;
  }

  display: flex;
  // justify-content: stt;
  flex-direction: column;
  z-index: 10000;
  min-width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  height: 100%;
  overflow: hidden;
  background: black;
  position: fixed;
  top: 0px;
  left: 0px;

  #div-video-container {
    position: absolute !important;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
    display: flex;
    justify-content: center;
  }

  video {
    max-width: 100%;
    max-height: 100vh;
    overflow: hidden;
  }

  button.close {
    // position: absolute;
    // top:25px;
    // right: 25px;
    color: white;
  }

  button.keyboard {
    position: absolute;
    bottom: 65px;
    right: 25px;
    color: white;
  }

  button.undo {
    // position: absolute;
    // bottom: 55px;
    // left: 25px;
    color: white;
  }


  button.scan {
    user-select: none;
    background: red;
    width: 60px;
    height: 60px;
    position: absolute;
    bottom: 50px;
    left: calc(50% - 30px);
    border-radius: 30px;
    border: 5px solid white;
    transition: .3s transform;

    &.active {
      transform: scale(1.3);
      transform-origin: center center;
      background: green;
    }


  }

  .progress {
    min-height: 42px;
  }

  .progress {
    // width: 80%;
    // margin-right: 10%;
    // position: absolute;
    max-width: 80%;
    min-width: 80%;

    // top: calc(70% - 25px);
    // left: calc(25%);
    input {
      // max-width: 50%;
    }
  }

  button.result {
    background: green;
    color: white;
    width: 200px;
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 100px);
    z-index: 1000;
    // border-radius: 25px;
  }

  button {
    z-index: 100;
  }

  .input-wrapper {
    position: fixed;
    bottom: 100px;
    left: 0px;
    width: 100%;
    display: flex;
    z-index: 200;

    .input-group {
      flex-wrap: nowrap;
      max-width: 250px;
    }
  }


}

.footer {
  line-height: 40px;
  background: white;
  border-top: 1px solid black;
  text-align: right;
  padding: 6px 24px;
  position: fixed;
  z-index: 1;
  bottom: 0;
  width: 100%;

  input {
    height: 2em;
    border: 0px;
    font-size: 16px;
    font-weight: 400;
  }
}

.text-red {
  color: red !important;
}

.orders-content {
  max-height: 107vh;
}

.toast {
  font-size: 1.1rem;
  max-width: 500px;
}

.ancillary-orders {
  margin-top: 12rem;
  position: fixed;
  bottom: 0px;
  height: 50%;
  width: 100%;
  background: white;
  border-top: 2px solid #333;
}

.contacts-divider {
  margin: 10px;
}

.pointer {
  cursor: pointer;
}

// .modal {
//   background: rgba(0, 0, 0, 0.7);
// }


app-picking-modal {

  .biginput {

    input,
    select,
    span {
      height: 80px;
      font-size: 48px;
      line-height: 68px;
      font-weight: normal !important;
      background: white;
      -webkit-appearance: none;

      &:focus {
        outline: none;
      }
    }

    sub {
      font-size: 200%;
    }

    // input {
    //   border: 1px solid #aaa;
    //   border-radius: 5px 0px 0px 5px;
    //   padding-left: 10px;
    // }

    select {
      border: 1px solid #aaa;
      border-left: 0px;
      border-radius: 0px 5px 5px 0px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.circle {
  border-radius: 50px;
  background: #f47e43;
  height: 32px;
  width: 32px;
  display: inline-flex;
  justify-content: center;
  line-height: 32px;
}


.dev {
  // .navbar {
  //   background-color: red;
  // }
}


.highlight:hover {
  background: red;
}

a.btn.btn-outline-dark:hover {
  color: #fff !important;
}


// .blocking-overlay {
//   position: fixed;
//   bottom: 10px;
//   right: 10px;
//   width: 200px;
//   height: 80px;
//   border-radius: 6px;
//   border: 2px solid theme-color("primary");
//   background: white;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   z-index: 100;
// }

.blocking-overlay {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 400px;
  height: 100px;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 100;
  border-radius: 20px;
  padding: 10px;
}


// .pac-container {
//   // bad way to reposition google maps dropdown
//   transform: translateY(-50px) translateX(-35px);
// }

.card-stage {
  margin-bottom: 28px !important;
  // width: 450px;
  // width: 100%;
  min-width: 280px;
  // min-width: 100%;
  min-height: 58px;


  .card-header {
    min-height: 58px;
  }

  .btn {
    // min-width: 90px;
  }
}

.card-stage-sibling::after {
  content: '';
  display: block;
  width: 40px;
  height: 40px;
  background: linear-gradient(-45deg, white, white, white, transparent, transparent);
  border: 1px solid #aaa;
  border-top: 0px;
  border-left: 0px;
  position: absolute;
  top: calc(100% - 20px);
  left: 30px;
  transform: rotate(45deg);
  z-index: 0;
}

.card-stage-child {
  margin-right: 28px;

}

.card-stage-child::before {
  content: '';
  display: block;
  width: 40px;
  height: 40px;
  background: linear-gradient(-45deg, white, white, white, transparent, transparent);
  border: 1px solid #aaa;
  border-top: 0px;
  border-left: 0px;
  position: absolute;
  top: calc(100% - 20px);
  left: calc(50% - 20px);
  transform: rotate(45deg);
  z-index: 0;
  top: calc(50% - 20px);
  left: calc(100% - 19px);
  transform: rotate(-45deg);
}

.card-stage-last::after {
  display: none;
}

// .modal-bg {
//   background: rgba(0, 0, 0, 0.9);
//   position: fixed;
//   top: 0px;
//   left: 0px;
//   width: 100%;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   z-index: 100000;
// }


.text-no-wrap {
  white-space: nowrap;
}


.margin-top-as-navbar {
  margin-top: 65px;
}

app-production-detail {}

app-picking-move-editor {
  display: block;

  table {
    border: 0px !important;
  }

  .spacer {
    background: transparent !important;
    border: 0px !important;
  }
}

app-barcode-reader-dynamsoft {
  z-index: 10000;
}

app-picking-leftovers {
  display: flex;
}


app-contact-picker {
  >ul.list-inline {
    display: flex;
    flex-wrap: wrap;

    >li {
      border: 1px solid #ccc;
      border-radius: 10px;
    }
  }



  table td {
    padding-bottom: 10px;
  }
}

@include media-breakpoint-down(md) {
  ul.list-inline {
    width: 100%;
    display: block;

    li {
      width: 100% !important;
    }
  }
}


app-order-inventory {
  z-index:3000000;
  #inventory {
    min-width: 1000px;
    max-width: 70%;
    box-shadow: 0px 0px 5px #333;
    background: theme-color("light");
    // max-height: 115vh;
    border: 2px solid #333;
    position: fixed;
    top: 64px;
    right: 10px;
    min-height: 90vh;
    max-height: 90vh;
    background-color: white;
    // z-index: 1000;
    min-width: 60vw;
    overflow-y: hidden;
  

  }

  // #inventory {

  // }



}



app-deal-budget {

  table {
    max-height: 200px;
    overflow: scroll;
    display: block;
  }
}


app-picking-package-location-picker {
  color: white;
  background-color: white;
  // position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  // overflow: hidden;
  // z-index: 10000;
  display: flex;
  flex-direction: column;

  // .nav-item {
  //   button.nav-link {
  //     color: white !important;

  //   }

  // }

  .tab-content {
    height: 100%;
  }

  .tab-content>.active {
    display: flex;
    height: 100%;
  }

  .nav-tabs .nav-link.active {
    color: $dark !important;
  }


  .result {
    position: absolute;
    bottom: 80px;
    left: 0%;
    width: 100%;
    height: calc(50% - 100px);
    z-index: 100000 +1;
    padding: 10px;
    font-size: 150%;
  }

}




app-scanner, app-scanner-zxing {
  background-color: black !important;
  color: white;
  top: 0px !important;
  left: 0px;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 100000;
  display: block;
  position: fixed;

  > div {
    position: relative;
    width: 100vw;
  }

  canvas {
    pointer-events: none;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 100000;
    position: absolute;
    object-fit: cover;
  }

  .code {
    justify-content: space-between;
    align-items: center;
  }

  .app-toolbar {
    position: fixed;
    bottom: 20px;
    left: 0px;
    width: 100vw;
    z-index: 100001;
  }

  video {
    width: 100vw;
    height: calc(100vh - 100px);
    object-fit: cover !important;
  }
}


.text-stroke {
  stroke: black;
}

.scrollable {
  overflow: scroll !important;
}

// v2
.changed {
  transition: all 0.2s ease-out;
  background-color: $primary !important;
  color: white !important;

  * {
    color: white !important;
  }
}


.desktop-page {
  .table-bordered {
    tr {
      min-height: 50px;
    }
  }
}

app-picking-editor-page {


  position: relative;

  .modal-backdrop {
    position: absolute;
  }


  app-scanner {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    z-index: 1000000;
  }

  .btn-pill {
    background-color: #f47e43;
    border-radius: 30px;
    height: 60px;
    z-index: 1001;
    border: 2px solid white;
  }

  .btn-scan {
    background-color: #f47e43;
    border-radius: 50%;
    min-width: 60px;
    height: 60px;
    z-index: 1001;
    border: 2px solid white;
  }


  .btn-group-scan {
    z-index: 1100;
    display: flex;
    // position: fixed;
    width: 100vw;
    align-items: center;
    height: 80px;
    bottom:0px;
    left: 0px;
    right:20px;
    padding:10px
  }
}


app-picking-editor-page,
app-flash-sale-order-editor,
app-pack-page,
app-pick-pack-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  .table-wrapper {
    height: 100%;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }


}


app-virtual-keypad {

  &.open {
    position: absolute;
  }

  position:relative;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  .btn {
    min-height: 60px;
    color:white;
    font-size: 115%;
  }
  .overlay {
    z-index:2000000;
    background-color: #333;
    position:absolute;
    top:0px;
    left:0px;
    width: 100%;
    height: 100%;
  }
}

app-order-header {
  display: flex;
  flex-direction: column;
}

app-keyboard-scanner {
  // width: 40px;
  // height: 40px;
  // border-radius: 20px;

  // background-color: red;
  // box-shadow: 0px 0px 20px #333;
  // color: white;
  // margin-left: auto;


  &.active {
    color: $primary !important;
  }

  &.invalid {
    z-index: 1000;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    color: white !important;
    background: red !important;

    >* {
      display: none !important;
    }
  }

  &.valid {
    z-index: 1000;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    background: green !important;
    color: white !important;

    >* {
      display: none !important;
    }
  }

}


bar-loader {
  background: $dark !important;
  display: block;
  width: 100%;
  min-height: 7px;
  // position: fixed;
  top: 0px;
  border-bottom: 0;

  // position: absolute;
  // bottom: -7px;
  .progress {
    height: 7px
  }
}

app-navbar {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $primary;
  background: $dark !important;

  // .navbar {
  //   background: $dark !important;
  // }

 

  // bar-loader .progress:after {
  //   display: block;
  //   content: ' ';
  //   background: transparent;
  //   width: 100%;
  //   height: 100%;
  //   // position: fixed;
  //   left: 0px;
  //   z-index: 2000000;

  // }


}


app-badge-kiosk {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: $primary !important;
}


app-sale-print {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 240px;
  background: #333;
  overflow: hidden;
}

app-sale-order-editor {
  app-picking-editor-page, app-purchase-order, app-production-editor
   {
    position: fixed;
    top:30px;
    right: 30px;
    width: 40%;
    height: calc(100% - 60px);
    box-shadow: 0px 0px 40px 2px black;
    background-color: white;
    z-index: 1000000;
  }
}

app-activity-editor {
  position: fixed;
  top:30px;
  left: 30px;
  width: 50%;
  height: auto; // Change from fixed height to auto
  max-height: calc(100% - 60px); // Keep this to prevent oversized modals
  min-height: auto; // Allow it to be as small as its content
  box-shadow: 0px 0px 40px 2px black;
  background-color: white;
  z-index: 1000000;
  overflow-y: auto;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}


app-production-editor {
  position: fixed;
  top:30px;
  left: 30px;
  width: 50%;
  height: auto; // Change from fixed height to auto
  max-height: calc(100% - 60px); // Keep this to prevent oversized modals
  min-height: auto; // Allow it to be as small as its content
  box-shadow: 0px 0px 40px 2px black;
  background-color: white;
  z-index: 1000000;
  overflow-y: auto;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  // Style for the content
  .modal-content {
    flex: 1 1 auto;
    overflow-y: auto;
    max-height: calc(80vh - 40px); // Subtract padding
  }

}



app-purchase-order {
  flex-direction: column;
  display: flex;
  height: 100%;
}

app-connect-to {
  display: inline-block;
}


app-flow,
app-dashboard {
  height: 100%;
  width: 100%;
  display: block;
  background-image: url('../assets/flow.jpg');
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;

  .w-10{
    width:  10%;
  }

  .w-65{
    width:  65%;
  }

  a {
    color: white;
  }

  app-flow-inspector{
    background-color: rgba(0, 0, 0, 0.675);

  }

  app-flow-sidebar,
  app-flow-dashboard-sidebar {
    background-color: rgba(0, 0, 0, 0.675);
    .list-unstyled li.active {
      color: $success
    }
  }
  

  app-action-card {

    .resize-handle-top,
    .resize-handle-bottom,
    .resize-handle-left,
    .resize-handle-right,
    .resize-handle-top-left,
    .resize-handle-top-right,
    .resize-handle-bottom-left,
    .resize-handle-bottom-right {
      position: absolute;
    }

    .resize-handle-top,
    .resize-handle-bottom {
      height: 5px;
      cursor: ns-resize;
      width: 100%;
    }

    .resize-handle-top {
      top: 0;
    }

    .resize-handle-bottom {
      bottom: 0;
    }

    .resize-handle-left,
    .resize-handle-right {
      height: 100%;
      cursor: ew-resize;
      width: 5px;
    }

    .resize-handle-left {
      left: 0;
    }

    .resize-handle-right {
      right: 0;
    }

    .resize-handle-top-left,
    .resize-handle-top-right,
    .resize-handle-bottom-left,
    .resize-handle-bottom-right {
      width: 10px;
      height: 10px;
      background: rgba(0, 0, 0, 0.5);
      cursor: nwse-resize;
    }

    .resize-handle-top-left {
      top: 0;
      left: 0;
    }

    .resize-handle-top-right {
      top: 0;
      right: 0;
    }

    .resize-handle-bottom-left {
      bottom: 0;
      left: 0;
    }

    .resize-handle-bottom-right {
      bottom: 0;
      right: 0;
    }
  }



}

.bg-trasparent {
  background: transparent;
}

.modal-backdrop {
  overflow: hidden;
}



.cdk-drag-preview {
  background     : #eee;
  padding        : 20px 10px;
  box-sizing   : border-box;
  color          : rgba(0, 0, 0, 0.87);
  display        : flex;
  align-items    : center;
  font-size      : 14px;
  border-radius: 4px;
  box-shadow   : 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
}


[cdkDragHandle] {
  cursor: move;
}

app-catpro {
  
  .p-inputtext,.p-chips {
    width: 100%;
  }

  ul {
    padding-top:0px;
    padding-bottom:0px;
    padding-left: 1rem;
  }
  
  #dialog::backdrop {
    background-color: rgba(0,0,0,0.12);
  }

  datalist {
    position: absolute;
    left:0px;
    max-height: 20em;
    border: 0 none;
    overflow-x: hidden;
    overflow-y: auto;
  }
}


dialog {
  --#{$prefix}modal-zindex: #{$zindex-modal};

  position: fixed;
  z-index: var(--#{$prefix}modal-zindex);
  overflow-x: hidden;
  overflow-y: auto;

  width: 80%;
  margin-right: auto;
  margin-left: auto;

  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;
}

app-catalog {
  app-order-inventory {

    display: flex;
    flex-direction: column;

    position: inherit;

    #menu {
      margin: 10px;
    }
    #inventory {
      position: inherit;
      min-width: auto;
      max-width: 100%;
      width: 100%;
      box-shadow: none;
      border:0px;
      display: flex !important;
    }
  }
}

app-contact-picker2 {
  max-height: 400px;
  display: block;
  overflow-y: scroll;
  .dropdown-menu {
    
  }
}

app-flash-sale-order-editor {
  app-order-inventory {
    z-index: 1;
    
    .offcanvas-body {
      padding: 0px !important;
    }

    padding: 0px;
    #inventory {
      top: 0px;
      left:0px;
      right: 0px;
      bottom:0px;
      min-width: auto !important;
      max-width: none !important;
      // width: calc(100% - 20px) !important;
      position: inherit;
      box-shadow: none;
      border :none;
      display: block;
      
      // table {
      //   border:none !important;
      // }
      
      thead {
        display: none;
      }
      hide some cols 
      TODO hack
      table td:nth-child(1),table th:nth-child(1) {
        display: none;
      }

      table td:nth-child(3),table th:nth-child(3) {
          display: none;
      }

      table td:nth-child(5),table th:nth-child(5) {
        display: none;
      }
      table td:nth-child(6),table th:nth-child(6) {
        display: none;
      }
    }
  }
}

.form-control:focus {
  box-shadow:none;
}

app-contracts [contenteditable="true"] p,
app-billing [contenteditable="true"] p  {
  margin-bottom: 0 !important;

}


app-calendar-widget {
  .tab-pane {
    max-height: calc(90vh - 70px);
    overflow-y: scroll;
  }
  display: block;
  
}